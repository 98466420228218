.terms {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80vw;
  height: 70vh;
  color: black;
  background: white;
  z-index: 1000;
  transform: translate(-50%, -50%);
  border-radius: 20px;
}

.terms__inner {
  padding: 20px 30px;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}

.terms__inner h2 {
  font-weight: 600;
  font-size: 1.3rem;
}

.terms__inner ol {
  padding-left: 40px;
}

.terms__inner ol li p {
  padding-left: 0;
}

.terms__inner p {
  padding-left: 20px;
  line-height: 160%;
}

.terms__inner__cross {
  position: fixed;
  top: 20px;
  right: 35px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.terms__inner__cross__line {
  width: 100%;
  height: 4px;
  border-radius: 3px;
  background: black;
}

.terms__inner__cross__line:nth-child(1) {
  transform: rotate(45deg) translateX(9px) translateY(9px);
}

.terms__inner__cross__line:nth-child(2) {
  transform: rotate(-45deg) translateX(9px) translateY(-9px);
}

@media (max-width: 600px) {
  .terms__inner {
    padding: 10px 20px;
    position: relative;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    font-size: 0.9rem;
  }

  .terms__inner ol {
    padding-left: 20px;
  }

  .terms__inner p {
    padding-left: 5px;
  }

  .terms__inner__cross {
    right: 20px;
    top: 10px;
    width: 20px;
  }

  .terms__inner h2 {
    font-weight: 600;
    font-size: 1rem;
    padding-right: 40px;
  }

  .terms__inner h1 {
    font-weight: 600;
    font-size: 1.3rem;
    padding-right: 10px;
  }
}
