@import url(./fonts/fonts.css);

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  user-select: none;
}

:root {
  --font-family: "Poppins";
  --blue: #2429ad;
  --darkblue: #000a71;
  --magenta-red: #a52256;
  --red: #d66d69;
  --lightblue: #505bde;
  --yellow: #e0c153;
  --green: #78e37e;
  --lightpurple: #505bde;
  --purple: #7215af;
  --lightblack: #1e1e1e;
  --cubic-transition: cubic-bezier(0, -0.03, 1, 0.64);
}

a {
  color: inherit;
  text-decoration: none;
}

html,
body {
  overflow-x: hidden;
}

body {
  font-family: var(--font-family);
}

.app {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

section {
  overflow: hidden;
}

.lightItalic {
  font-style: italic;
  font-weight: 300;
}

.mediumItalic {
  font-style: italic;
  font-weight: 500;
}

.scrollbar-track {
  z-index: 1001 !important;
}

.scrollbar-thumb {
  z-index: 1002 !important;
}

.buttonHover {
  transition: 0.2s var(--cubic-transition);
}

.buttonHover:hover {
  background: white;
  color: var(--lightblack);
  cursor: pointer;
}

.headingAnim {
  transform: translateX(0) !important;
  opacity: 1 !important;
  transition: 0.3s !important ;
}

.subheadingAnim {
  transform: translateX(0) !important;
  opacity: 1 !important;
  transition: 0.3s !important;
}

.headingAnimInverted {
  transition: 0.3s !important;
  transform: translateX(0) !important;
  opacity: 1 !important;
}

.subheadingAnimInverted {
  transition: 0.3s !important;
  transform: translateX(0) !important;
  opacity: 1 !important;
}
