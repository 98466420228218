.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 28px 0;
  color: white;
  z-index: 1000 !important;
  background: var(--blue);
  transition: 0.3s;
  transform: translateY(-110%);
  opacity: 1;
}

.visible {
  transform: translateY(0);
  opacity: 1;
}

.transparent {
  background: transparent;
}

.header__logo {
  cursor: pointer;
}

.header__inner {
  max-width: 1770px;
  width: 100%;
  padding: 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__nav {
  display: flex;

  align-items: center;
  gap: 50px;
}

.header__link {
  font-weight: 500;
  cursor: pointer;
}

.header__button {
  border-radius: 35px;
  padding: 12px 30px;
  font-weight: 500;
  box-shadow: 0 0 0 1.5px white;
  transition: 0.2s var(--cubic-transition);
}

.hoverUnderline {
  position: relative;
}

.hoverUnderline::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 1.5px;
  width: 0;
  background: white;
  transition: 0.2s;
}

.hoverUnderline:hover::after {
  width: 100%;
}

.header__menu {
  width: 25px;
  height: 25px;
  position: relative;
  display: none;
}

.header__menu__line {
  position: absolute;
  width: 100%;
  height: 2.5px;
  background: white;
  border-radius: 3px;
  transition: 0.3s;
}

.header__menu__line:nth-child(1) {
  top: 0;
}

.header__menu__line:nth-child(2) {
  top: 9px;
}

.header__menu__line:nth-child(3) {
  top: 18px;
}

.menu {
  position: fixed;
  width: 200px;
  top: 85px;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  z-index: 1000;
  text-align: center;
  border-bottom-left-radius: 20px;
  padding: 20px;
  transform: translateX(110%);
  transition: 0.3s;
  backdrop-filter: blur(3px);
  background: rgba(36, 41, 173, 0.8);
}

.menuVisible {
  transform: translateX(0);
}

.menuIconTrasform .header__menu__line:nth-child(1) {
  transform: translate(1px, 9px) rotate(45deg);
}
.menuIconTrasform .header__menu__line:nth-child(2) {
  opacity: 0;
}

.menuIconTrasform .header__menu__line:nth-child(3) {
  transform: translate(1px, -9px) rotate(-45deg);
}

@media screen and (max-width: 1020px) {
  .header__nav {
    display: none;
  }
  .header__menu {
    display: flex;
  }
}

@media screen and (max-width: 820px) {
  .header__inner {
    padding: 0 20px;
  }
}
