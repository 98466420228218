.intro__banner {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  background: url(../../assets/introbg.png) no-repeat top center / cover;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  color: white;
  padding-bottom: 80px;
  padding-top: 130px;
}

.intro__banner__title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 110px;
}

.lower {
  margin-bottom: 50px;
}

.intro__banner__row {
  display: flex;
  align-items: center;
  gap: 60px;
}

.intro__banner__column.link:hover .intro__banner__link__icon {
  right: -5px;
}

.intro__banner__column.link:hover .buttonTextUnderline::after {
  width: 100%;
}

.intro__banner__link__icon__container {
  position: relative;
  right: 0;
  opacity: 0;
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 6px var(--green);
  border-radius: 50%;
  transform: translateX(-40px);
  transition: 0.3s;
  transition-delay: 0.25s !important;
}

.intro__banner__link__icon__container:hover .intro__banner__link__icon {
  transform: rotate(135deg) scale(1.05);
}

.intro__banner__column.link:hover .intro__banner__link__title {
  top: 7px;
}

.intro__banner__link__icon {
  width: 30px;
  height: 30px;
  transition: 0.3s;
}

.intro__banner__link__title {
  position: relative;
  top: 0;
  opacity: 0;
  transition: 0.3s;
  transition-delay: 0.3s;
  transform: translateX(-40px);
}

.introBannerLinkAnim {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.intro__banner__column__text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.intro__banner__column__text__block {
  overflow: hidden;
}

.intro__banner__column__text__block p {
  opacity: 0;
  transform: translateY(20px);
  transition: 0.3s;
}

.intro__banner__column__text__block:nth-child(1) p {
  transition-delay: 0.35s;
}

.intro__banner__column__text__block:nth-child(2) p {
  transition-delay: 0.4s;
}

.intro__banner__column__text__block:nth-child(3) p {
  transition-delay: 0.45s;
}

.introBannerTextBlockAnim {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.intro__banner__link__title {
  color: var(--green);
  text-align: center;
  font-size: 22px;
  margin-top: 10px;
}

.intro__banner__row,
.intro__banner__column__row {
  display: flex;
  align-items: center;
}

.intro__banner__column__row {
  gap: 20px;
}

.intro__banner__column__row__item {
  transition: 0.3s;
}

.intro__banner__column__row__item:hover {
  border-radius: 20px;
  transform: translateX(5px);
}

.intro__banner__column__row__item:hover .intro__banner__column__row__item__number {
  transform: translateX(5px);
}

.intro__banner__column__row__item:hover .intro__banner__column__row__item__text {
  transform: translate(5px, -5px);
}

.intro__banner__column__row__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  width: 230px;
  height: 200px;
  padding: 30px;
  gap: 5px;
  opacity: 0;
  transition: 0.3s;
  opacity: 0;
  transform: translateX(-30px);
}

.intro__banner__column__row__item:nth-child(1) {
  background: var(--lightpurple);
  transition-delay: 0.2s;
}

.intro__banner__column__row__item:nth-child(2) {
  background: var(--yellow);
  color: var(--darkblue);
  transition-delay: 0.25s;
}

.intro__banner__column__row__item:nth-child(3) {
  background: var(--red);
  transition-delay: 0.3s;
}

.introBannerColumnRowItemAnim {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.intro__banner__column__row__item__number {
  font-size: 60px;
  font-weight: 600;
  transition: 0.3s;
}

.intro__banner__column__row__item__text {
  font-size: 18px;
  transition: 0.3s;
}

.intro__banner__column__text {
  font-size: 0.9rem;
}

.introBannerWordAnim {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.intro__banner__word {
  opacity: 0;
  transform: translateY(60px);
  transition: 0.5s;
}

.intro__banner__title.upper .intro__banner__word:nth-child(1) {
  opacity: 0;
  transform: translateY(60px);
}

.intro__banner__title.upper .intro__banner__word:nth-child(3) {
  opacity: 0;
  transform: translateY(60px);
  transition-delay: 0.05s;
}

.intro__banner__title.lower .intro__banner__word:nth-child(1) {
  opacity: 0;
  transform: translateY(60px);
  transition-delay: 0.1s;
}

.intro__banner__title.lower .intro__banner__word:nth-child(2) {
  opacity: 0;
  transform: translateY(60px);
  transition-delay: 0.15s;
}

.intro__banner__title.lower .intro__banner__word:nth-child(3) {
  opacity: 0;
  transform: translateY(60px);
  transition-delay: 0.2s;
}
.puzzle {
  width: 250px;
  height: 250px;
}

.puzzle__inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.puzzle__parent {
  width: 230px;
  height: 230px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%) rotate(45deg);
  transition: 0.3s;
}

.puzzleParentAnim {
  transform: translate(-50%, -50%) rotate(0);
}

.puzzleParentAnimBackwards {
  transform: translate(-50%, -50%) rotate(45deg);
}

.puzzle__child {
  position: absolute;
  width: 150px;
  height: 150px;
  opacity: 0;
  background: url(../../assets/puzzle.png) no-repeat center center / contain;
  transition: 0.3s;
}

.puzzleFirstChildAnim {
  position: absolute;
  top: -200px;
  left: -100px;
  transform: translate(0, 0) rotate(0);
  opacity: 1;
}

.puzzleFirstChildAnimBackwards {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  opacity: 0;
}

.puzzleSecondChildAnim {
  top: -258px;
  left: 60px;
  transform: translate(0, 0) rotate(0);
  opacity: 1;
  transition-delay: 0.1s;
}

.puzzleSecondChildAnimBackwards {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-135deg);
  opacity: 0;
}

.puzzleThirdChildAnim {
  right: -120px;
  top: -170px;
  transform: translate(0, 0) rotate(0);
  opacity: 1;
  transition-delay: 0.2s;
}

.puzzleThirdChildAnimBackwards {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%) rotate(-180deg);
  opacity: 0;
}

.buttonTextUnderline {
  position: relative;
}

.buttonTextUnderline::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 3px;
  width: 0;
  background: var(--green);
  transition: 0.2s;
}

.intro__banner__column .intro__banner__column.link {
  display: none;
}

@media screen and (max-width: 1420px) {
  .intro__banner__title {
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    font-size: 100px;
  }
  .intro__banner__word:nth-child(1) {
    align-self: flex-start;
  }
  .intro__banner__title.upper {
    position: relative;
  }
  .puzzle {
    position: absolute;
    top: 300px;
    width: 150px;
    height: 150px;
  }
  .puzzle__parent {
    width: 140px;
    height: 140px;
  }
  .puzzle__child {
    display: none;
  }
  .intro__banner__title.lower .intro__banner__word:nth-child(1) {
    align-self: center;
  }
  .intro__banner__title.lower .intro__banner__word:nth-child(2) {
    align-self: flex-start;
  }
  .intro__banner__row {
    flex-direction: column;
    position: relative;
  }

  .intro__banner__column.link {
    order: 3;
  }
  .intro__banner__column__text {
    align-items: flex-start;
  }
  .intro__banner__column__text__block:nth-child(1) {
    width: 50%;
    text-align: left;
  }

  .intro__banner__column__text__block:nth-child(2) {
    width: 50%;
    text-align: right;
    align-self: flex-end;
  }
  .intro__banner__column__text__block:nth-child(3) {
    width: 50%;
    text-align: left;
  }
}

@media screen and (max-width: 820px) {
  .intro__banner__title {
    font-size: 55px;
  }
  .intro__banner__column__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .intro__banner__row {
    max-width: 420px;
    width: 100%;
    margin: 0 auto;
  }
  .intro__banner__column__text__block:nth-child(1) {
    width: 70%;
  }

  .intro__banner__column__text__block:nth-child(2) {
    width: 70%;
  }
  .intro__banner__column__text__block:nth-child(3) {
    width: 70%;
  }
  .puzzle {
    position: absolute;
    top: 150px;
    width: 100px;
    height: 100px;
  }
  .puzzle__parent {
    width: 90px;
    height: 90px;
  }
  .intro__banner__column__row__item {
    width: auto;
    height: 150px;
  }
  .intro__banner__column__row__item__number {
    font-size: 42px;
  }
  .intro__banner__link__icon__container {
    width: 110px;
    height: 110px;
  }
  .intro__banner {
    padding-bottom: 20px;
  }
  .intro__banner__row .intro__banner__column.link {
    display: none;
  }
  .intro__banner__column .intro__banner__column.link {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
