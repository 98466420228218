@font-face {
  font-family: "Poppins";
  src: url(./Poppins-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url(./Poppins-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "Poppins";
  src: url(./Poppins-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: "Poppins";
  src: url(./Poppins-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "Poppins";
  src: url(./Poppins-Light.ttf);
  font-weight: 300;
}
@font-face {
  font-family: "Poppins";
  src: url(./Poppins-ExtraLight.ttf);
  font-weight: 200;
}
@font-face {
  font-family: "Poppins";
  src: url(./Poppins-Thin.ttf);
  font-weight: 100;
}
